.Content[data-state="open"] {
    animation: openContent 150ms ease-in;
  }
  
  .Content[data-state="closed"] {
    animation: closedContent 150ms ease-in;
  }
  
  @keyframes openContent {
    from {
      height: 0
    }
    to {
      height: var(--radix-accordion-content-height)
    }
  }
  
  @keyframes closedContent {
    from {
      height: var(--radix-accordion-content-height)
    }
    to {
      height: 0
    }
  }
